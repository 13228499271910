import React, { useState } from 'react';
import './Main.css'; // 確保引入了 CSS 文件
import { AllCups } from './Data/CupsDatabase';
import { useUser } from './UserContext'; // 导入useUser

const Main = (user) => {

  const { userData, setUserData } = useUser();  
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCup, setSelectedCup] = useState(null);
  const [activeTab, setActiveTab] = useState('tab1');
  const [roundDetails, setRoundDetails] = useState(null);
  const [debugInfo, setDebugInfo] = useState([]);

  const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();

  const calculateTimeLeft = (startTime) => {
    const difference = +new Date(startTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60)
      };
    }

    return timeLeft;
  };

  const handleDetailClick = async (cup) => {
    setSelectedCup(cup);
    setModalVisible(true);
    await loadRoundData(cup.id, cup.Round);
  };

  const loadRoundData = async (cupName, rounds) => {
    const basePath = `/Data/${cupName}_r`;
    let roundData = {};
    let debugMessages = [];
    
    for (let i = 0; i <= rounds; i++) {
      try {
        const filePath = `${basePath}${i}.json`;
        debugMessages.push(`Trying to fetch: ${filePath}`);
        const response = await fetch(filePath);
        if (response.ok) {
          roundData[`r${i}`] = await response.json();
          debugMessages.push(`Successfully loaded: ${filePath}`);
        } else {
          roundData[`r${i}`] = [];
          debugMessages.push(`File not found: ${filePath}`);
        }
      } catch (error) {
        roundData[`r${i}`] = [];
        debugMessages.push(`Error loading file: ${basePath}${i}.json, Error: ${error.message}`);
      }
    }
    setRoundDetails(roundData);
    setDebugInfo(debugMessages);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedCup(null);
  };

  const renderRoundDetail = () => {
    if (!roundDetails) return <p>Loading...</p>;
    const totalPlayers = roundDetails.r0?.length || 0;
    return (
      <div>
        <h3>{selectedCup.name} - 回合詳情</h3>
        <p>總參與人數: {totalPlayers}</p>
        {Object.keys(roundDetails).map((key, index) => (
          <div key={key}>
            <h4>第 {index} 回合</h4>
            <p>入圍人數: {roundDetails[key].length}</p>
            {userData.instagram && (
              roundDetails[key].includes(userData.instagram) ? (
                <p style={{ color: 'green' }}>你已入圍此回合</p>
              ) : (
                <p style={{ color: 'red' }}>你未能入圍此回合</p>
              )
            )}
          </div>
        ))}
        <h4>Debug Info:</h4>
        <pre>{debugInfo.join('\n')}</pre>
      </div>
    );
  };

  const renderTabContent = () => {
    if (!selectedCup) return null;

    switch (activeTab) {
      case 'tab1':
        return (
          <div>
            <h3>Tab 1: {selectedCup.name}</h3>
            <p>Start Time: {new Date(selectedCup.startTime).toLocaleString()}</p>
            <p>Players: {selectedCup.players}</p>
          </div>
        );
      case 'tab2':
        return (
          <div>
            <h3>RoundDetail: {selectedCup.name}</h3>
            <p>Task Points: {selectedCup.taskPoints}</p>
            <p>Rewards Type: {selectedCup.rewardsType}</p>
          </div>
        );
      case 'tab3':
        return (
          <div>
            <h3>Tab 3: {selectedCup.name}</h3>
            <p>Additional Info: {selectedCup.additionalInfo}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='cup-container'>
        userid:{userId}<br/>
        <ul className="cup-list">
          Active Cup:
          {Object.values(AllCups).filter(cup => new Date(cup.startTime) > new Date()).map(cup => {
            const timeLeft = calculateTimeLeft(cup.startTime);
            return (
              <li key={cup.id} className="cup-item" style={{
                backgroundImage: `url(${cup.background[0]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
                <div className='cup-time'>
                  {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m
                </div>              
                <div className='cup-detail top'>
                price pool:<br/>
                <span style={{ fontSize: '5vh', color:'#0000009c' }}>{cup.taskPoints} {cup.rewardsType}</span>
                </div>
                <div className='cup-detail bot'>
                <span style={{ fontSize: '5vh', color:'#000' }}>{cup.name}</span><br/>              
                {cup.players}Players
                </div>
                <button className='cup-button' onClick={() => handleDetailClick(cup)}>Detail</button>
              </li>
            );
          })}
        </ul>

        {modalVisible && selectedCup && (
          <div className='modal'>
            <div className='modal-content'>
              <span className='close' onClick={closeModal}>&times;</span>
              <h2>{selectedCup.name}</h2>
              <div className='cuptab-buttons'>
                <button 
                  className={activeTab === 'tab1' ? 'active' : ''} 
                  onClick={() => setActiveTab('tab1')}
                >
                  Info
                </button>
                <button 
                  className={activeTab === 'tab2' ? 'active' : ''} 
                  onClick={() => setActiveTab('tab2')}
                >
                  RoundDetail
                </button>
                <button 
                  className={activeTab === 'tab3' ? 'active' : ''} 
                  onClick={() => setActiveTab('tab3')}
                >
                  Rules
                </button>
              </div>
              <div className='cuptab-content'>              
                {renderTabContent()}
                {activeTab === 'tab2' ? renderRoundDetail() : ''}
              </div>
              <button className='betButton'>Bet</button>
            </div>
          </div>
        )}
    </div>
  );
};

export default Main;