import React, { memo, useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import {  MdAssignment, MdPeople, MdLeaderboard } from 'react-icons/md';
import { FaCat, FaGamepad } from 'react-icons/fa';
import { FaUser } from "react-icons/fa";
import { BiFootball } from "react-icons/bi";
import useSound from 'use-sound'; // 导入 useSound 钩子
const buttonClickSound = `${process.env.PUBLIC_URL}/asset/click2.mp3`; // 更正导入路径

const FooterLink = memo(({ to, icon: Icon, text, isActive, onClick, main }) => (
  <Link to={to} className={`footer-link ${main ? 'game-link' : ''} ${isActive ? 'active' : ''}`} onClick={onClick}>
    <div className={`footer-item ${main ? 'game-item' : ''}`}>
      <div className={`footer-icon ${main ? 'game-icon' : ''}`}>
        <Icon />
      </div>
      <span className="footer-text">{text}</span>
    </div>
  </Link>
));

function Footer() {
  const location = useLocation();
  const [playButtonSound] = useSound(buttonClickSound, { volume: 0.5 }); // 创建音效播放函数
  const [isFooterVisible, setFooterVisible] = useState(true); // 新增状态管理

  const handleClick = useCallback(() => {
    playButtonSound(); // 播放按钮音效
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
  }, [playButtonSound]);

  const toggleFooterVisibility = () => {
    setFooterVisible(!isFooterVisible); // 切换显示状态
  };

  const isActive = (path) => location.pathname === path;

  return (
    <footer className="App-footer">      
      {isFooterVisible && (
        <div className="footer-content">
          <FooterLink to="/task" icon={MdAssignment} text="TASK" isActive={isActive('/task')} onClick={handleClick} main={false} />
          <FooterLink to="/main" icon={BiFootball} text="Main" isActive={isActive('/main')} onClick={handleClick} main={true} />
          <FooterLink to="/profile" icon={FaUser} text="Profile" isActive={isActive('/profile')} onClick={handleClick} main={false} />
        </div>
      )}
    </footer>
  );
}

export default memo(Footer);
