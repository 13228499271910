import React from 'react';

const Background = () => {
  return (
    <div className='Background'>
      {/* Task component content */}
    </div>
  );
};

export default Background;