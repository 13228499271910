import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB8zo4rBxM2OW7esrCv5yfsRYMyhPIKlss",
    authDomain: "ball-c67d8.firebaseapp.com",
    projectId: "ball-c67d8",
    storageBucket: "ball-c67d8.firebasestorage.app",
    messagingSenderId: "1008668634964",
    appId: "1:1008668634964:web:ba5d1561481f6b810362c1",
    measurementId: "G-QW3GFH1T84"
  };

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
const storage = getStorage(app);
const analytics = app.analytics();
export { db, storage, analytics, firebase };