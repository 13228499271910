import './App.css';
import { WebApp } from '@twa-dev/sdk';
import React, { useEffect, useState, memo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { db } from './firebase';
import { UserProvider, useUser } from './UserContext';

import Task from './Task';
import Main from './Main';
import Profile from './Profile';
import Footer from './Components/Footer';
import Background from './Background.js';

const manifestUrl = 'https://catz.games/tonconnect-manifest.json';

const App = memo(() => {

  useEffect(() => {
    // Dynamically load Eruda script in development mode
    if (process.env.NODE_ENV === 'development') {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/eruda';
      script.onload = () => {
        if (window.eruda) {
          window.eruda.init();
        }
      };
      document.body.appendChild(script);
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();
      tg.disableVerticalSwipes();
      if (isMobile) {
      tg.requestFullscreen();
      }
      tg.unlockOrientation();     // tg.bac

      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    }
  }, []);
 
  return (
    <UserProvider>
      <Router>
        <TonConnectUIProvider
          manifestUrl={manifestUrl}
          actionsConfiguration={{
            twaReturnUrl: 'https://t.me/catz_community',
          }}
        >
          <AppContent />
        </TonConnectUIProvider>
      </Router>
    </UserProvider>
  );
});

const AppContent = memo(() => {
  const { setUserData } = useUser();

  const [inviterId, setInviterId] = useState(-1);
  const [showWelcomePage, setShowWelcomePage] = useState(false);
  const [initializationComplete, setInitializationComplete] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useInitializeUser({
    setUserData,
    setInviterId,
    setShowWelcomePage,
    setInitializationComplete,
  });

  useEffect(() => {
    if (initializationComplete) {
      if (showWelcomePage) {
        navigate('/welcome');
        setShowWelcomePage(false);
      } else if (location.pathname === '/') {
        navigate('/main');
      }
    }
  }, [initializationComplete, showWelcomePage, navigate, location.pathname]);

  return (
    <div className="AppContent">
      <Background />
      <Routes>
        <Route path="/" />
        <Route path="/main" element={<Main />} />
        <Route path="/task" element={<Task />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
      <Footer />
    </div>
  );
});

function useInitializeUser({
  setUserData,
  setInviterId,
  setShowWelcomePage,
  setInitializationComplete,
}) {
  useEffect(() => {
    console.log("Try to Initialize User");
    if (!window.Telegram) {
      console.error('window.Telegram 对象不存在。请确保在 Telegram WebApp 环境中运行。');
      return;
    }
    console.log(window.Telegram.WebApp);
    const initializeUser = async () => {
      const tg = window.Telegram?.WebApp;
      if (!tg) {
        console.error('Telegram WebApp 对象不存在。');
        return;
      }
      if (!tg.initDataUnsafe?.user) {
        console.error('无法获取 Telegram WebApp 用户数据。');
        return;
      }

      const currentUser = tg.initDataUnsafe.user;
      const userId = currentUser.id.toString();
      

      const userRef = db.collection('users').doc(userId);

      try {
        const doc = await userRef.get();
        const userData = doc.exists ? doc.data() : {};

        setUserData(userData);

        const isFirstLogin = !doc.exists;
        let showWelcomePage = false;

        if (isFirstLogin) {
          await handleFirstLogin(userRef, userData);
          showWelcomePage = true;
        }
        setShowWelcomePage(showWelcomePage);

        // 处理邀请人
        const startParam = tg.initDataUnsafe.start_param;
        if (startParam && isFirstLogin) {
          const inviterIdParam = startParam.split('_')[1];
          setInviterId(inviterIdParam);
          if (inviterIdParam) {
            await updateInviter(inviterIdParam, userId);
          }
        }
      } catch (error) {
        console.error('初始化用户时出错：', error);
      }
      setInitializationComplete(true);
    };

    initializeUser();
  }, [setUserData, setInviterId, setShowWelcomePage, setInitializationComplete]);
}

async function handleFirstLogin(userRef, userData) {
  const initialData = {
    ...userData,
    friends: [],
    points: 0,
  };
  await userRef.set(initialData);
}

async function updateInviter(inviterId, userId) {
  try {
    const inviterRef = db.collection('users').doc(inviterId);
    await inviterRef.update({
      friends: firebase.firestore.FieldValue.arrayUnion(userId),
      friendPoints:firebase.firestore.FieldValue.increment(1000),
      points:firebase.firestore.FieldValue.increment(1000)
    });
    console.log(`Inviter ${inviterId} updated successfully with user ${userId}.`);

    const userRef = db.collection('users').doc(userId);
    await userRef.update({
      friends: firebase.firestore.FieldValue.arrayUnion(inviterId),
      friendPoints:firebase.firestore.FieldValue.increment(1000),
      points:firebase.firestore.FieldValue.increment(1000)
    });
    console.log(`User ${userId} updated successfully with inviter ${inviterId}.`);
  } catch (error) {
    console.error('Error updating inviter or user:', error);
  }
}

export default App;
