import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import './Profile.css';
import { useTonWallet, TonConnectButton, useTonAddress , useTonConnectUI } from '@tonconnect/ui-react';
import { db } from './firebase'; // 确保导入Firebase数据库实例
import { useUser } from './UserContext'; // 导入useUser

const Profile = () => {
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('tab1');
  const [selectedCard, setSelectedCard] = useState(null);
  const [instagramUsername, setInstagramUsername] = useState('');
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  
  const { userData, setUserData } = useUser(); // 获取用户数据和更新函数

  const showMessage = useCallback((msg, duration = 3000) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), duration);
  }, []);

  
  const ResultCardDemo = [    
    { icon:'/asset/Icon_mb_cat.png',name: '$BALL Cup',rewardsType: 'point', taskPoints: 2000, type: 'USDT' , startTime: new Date('2025-03-05T10:00:00') },
    { icon:'/asset/Icon_mw_cat.png',name: '$USDT Cup',rewardsType: 'point', taskPoints: 1500, type: 'USDT' , startTime: new Date('2025-04-09T10:00:00') },
    { icon:'/asset/Icon_mb_cat.png',name: '$BALL Cup',rewardsType: 'point', taskPoints: 2000, type: 'USDT' , startTime: new Date('2025-03-05T10:00:00') },
    { icon:'/asset/Icon_mw_cat.png',name: '$USDT Cup',rewardsType: 'point', taskPoints: 1500, type: 'USDT' , startTime: new Date('2025-04-09T10:00:00') }
  ];

const ActiveCardDemo = [    
    { icon:'/asset/Icon_mw_cat.png',name: '$BALL Cup',rewardsType: 'point', taskPoints: 1234, type: '$Ball' , startTime: new Date('2025-05-01T10:00:00') },
    { icon:'/asset/Icon_mb_cat.png',name: '$USDT Cup',rewardsType: 'point', taskPoints: 122, type: 'USDT' , startTime: new Date('2025-04-09T10:00:00') }
  ];

  const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const closePopup = () => {
    setSelectedCard(null);
  };

  const handleInstagramChange = (e) => {
    setInstagramUsername(e.target.value);
  };

  const saveInstagramUsername = async () => {
    try {
      await db.collection('users').doc(userId).set({
        instagram: instagramUsername
      }, { merge: true });
      setUserData({ ...userData, instagram: instagramUsername }); // 更新UserContext中的userData
      showMessage('Instagram用户名已保存');
      toggleInstagramEdit();
    } catch (error) {
      showMessage('保存失败，请重试');
    }
  };

  const toggleInstagramEdit = () => {
    setIsEditingInstagram(!isEditingInstagram);
  };

  return (
    <>
   {message && <div className="message">{message}</div>}
    <div className="Profile-container">
      <div className="Profile-content">       
        <TonConnectButton />
        <p>
        <div>Telegram ID:{userId}</div>
        {!isEditingInstagram &&(
          <p>Instagram: {userData?.instagram || '未设置'}
          <button onClick={toggleInstagramEdit}>修改</button>
        </p>)}
        
        {isEditingInstagram && (
          <div>
            Instagram: 
            <input 
              type="text" 
              value={instagramUsername} 
              onChange={handleInstagramChange} 
              placeholder="输入Instagram用户名"
            />
            <button onClick={saveInstagramUsername}>保存</button>
          </div>
        )}
        </p>
      </div>

      <div className="Bet-content">
        <div className="tab-buttons">
          <button 
            className={activeTab === 'tab1' ? 'active' : ''} 
            onClick={() => setActiveTab('tab1')}
          >
            Active
          </button>
          <button 
            className={activeTab === 'tab2' ? 'active' : ''} 
            onClick={() => setActiveTab('tab2')}
          >
            Result
          </button>
        </div>
        {activeTab === 'tab1' && (
          <div className="tab-content">
            {ActiveCardDemo.map((card, index) => (
              <div key={index} className="info-card" onClick={() => handleCardClick(card)}>
                <img src={card.icon} alt={card.name} />
                <div>{card.name}</div>
                <div>Task Points: {card.taskPoints}</div>
                <div>Type: {card.type}</div>
                <div>Start Time: {card.startTime.toLocaleString()}</div>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'tab2' && (
          <div className="tab-content">
            {ResultCardDemo.map((card, index) => (
              <div key={index} className="info-card" onClick={() => handleCardClick(card)}>
                <img src={card.icon} alt={card.name} />
                <div>{card.name}</div>
                <div>Task Points: {card.taskPoints}</div>
                <div>Type: {card.type}</div>
                <div>Start Time: {card.startTime.toLocaleString()}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>

    {selectedCard && (
      <div className="popup">
        <div className="popup-content">
          <button onClick={closePopup}>Close</button>
          <img src={selectedCard.icon} alt={selectedCard.name} />
          <div>{selectedCard.name}</div>
          <div>Task Points: {selectedCard.taskPoints}</div>
          <div>Type: {selectedCard.type}</div>
          <div>Start Time: {selectedCard.startTime.toLocaleString()}</div>
        </div>
      </div>
    )}
    </>
  );
};

export default Profile;
