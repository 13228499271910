import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import './Task.css';
import { db, firebase } from './firebase';
import { useTonWallet, useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import Loader from './Loader';
//import { beginCell } from 'ton';

function Task({ user }) {
  // State variables
  const [userData, setUserData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [message, setMessage] = useState('');
  const [meme, setMeme] = useState(false);
  const [claimableTasks, setClaimableTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [loginRewards, setLoginRewards] = useState(0);
  const [friendRewards, setFriendRewards] = useState(0);
  const [gameRewards, setGameRewards] = useState(0);
  const [todayGamePoints, setTodayGamePoints] = useState(0);
  const [taskStarted, setTaskStarted] = useState(false);
  const [maxCombos, setMaxCombos] = useState(0);
  const [timeLeft, setTimeLeft] = useState({});

  //const colorScheme = window.Telegram.WebApp.colorScheme;

  const navigate = useNavigate();

  // Constants (Replace with your actual values)
  const BOT_TOKEN = '8140864316:AAE7L6bOgmcNWsSaB0QVwLlTCr9_67DXS7A';
  const ourWalletAddress = 'UQC0f1V-azUQcQU6EGweXYpjx6BKUQRgOvlkCsNYfJ7qursl';

  // TON Wallet hooks
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const userWalletAddress = useTonAddress();

  // Fetch user data
  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      console.log("Try access firebase(Read)")
      const doc = await userRef.get();

      if (doc.exists) {
        const data = doc.data();
        setUserData(data);
        setCompletedTasks(data.completedTasks || []);
        setRewards(data.completedTasks || []);
        setLoginRewards(data.loginPoints || 0);
        setFriendRewards(data.friendPoints || 0);
        setGameRewards((data.gamePoints || 0));
        setTodayGamePoints(data.todayGamePoints || 0);
        setMaxCombos(data.maxCombos || 0);
        setTaskStarted(data.pendingTransfer?.includes('catz') || false);
      }

      const allTasks = [
        { id: 'subscribe_1', name: 'Subscribe to CATS Telegram Channel',rewardsType: 'point', taskPoints: 1000, type: 'telegram', channelId: '@catz_community', url: 'https://t.me/catz_community', icon: '📢' }, // 新增任务
        { id: 'twitter', name: 'Follow CATS on X',rewardsType: 'point', taskPoints: 500, type: 'twitter', icon: '🐦' },
        { id: 'premium', name: 'Become a Telegram Premium User',rewardsType: 'point', taskPoints: 2000, type: 'premium', icon: '⭐' },
        { id: 'tonConnect', name: 'Connect Ton Wallet',rewardsType: 'point', taskPoints: 1500, type: 'ton_wallet', icon: '💼' ,airdrop:true},
        { id: 'tonTransfer', name: 'Complete a TON Transfer',rewardsType: 'point', taskPoints: 4000, type: 'ton_transfer', icon: '💰' },
        { id: 'name', name: 'Make Your Name contains 🌜/🌞',rewardsType: 'point', taskPoints: 1000, type: 'name_contains', icon: '🌓' },
        { id: 'firend', name: 'Have 5 or more friends',rewardsType: 'point', taskPoints: 10000, type: 'friends', icon: '👥' }, // 新增任务
        { id: 'combos_1', name: 'Reach 40 Max Combos',rewardsType: 'point', taskPoints: 1500, type: 'max_combos', requiredCombos: 40, icon: '🎯' }, // 新增任務
        { id: 'combos_2', name: 'Reach 80 Max Combos',rewardsType: 'point', taskPoints: 3000, type: 'max_combos', requiredCombos: 80, icon: '🎯' }, // 新增任務
        { id: 'progress', name: 'Reach Max Progress',rewardsType: 'point', taskPoints: 2000, type: 'todayGamePoints', requiredPoints: 500, icon: '🎮' }, // 新增任務
        { id: 'character_1', name: 'Reach 20000 Game Points',rewardsType: 'character', taskPoints: 0, type: 'gamePoints', requiredPoints: 20000, icon: '🎮',characterReward: 2 ,rewardsText: 'new character!'}, // 新增任務
        { id: 'character_2', name: 'Reach 30000 Game Points',rewardsType: 'character', taskPoints: 0, type: 'gamePoints', requiredPoints: 30000, icon: '🎮',characterReward: 3 ,rewardsText: 'new character!'}, // 新增任務
        { id: 'buy_skin', name: 'Buy a new skin', rewardsType: 'point', taskPoints: 2500, type: 'buy_skin', icon: '🛍️', airdrop:true},
        { id: 'story', name: 'Share a story',rewardsType: 'point', taskPoints: 2000, type: 'story', icon: '⭐' },
      ];

      setTasks(allTasks);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [fetchUserData, user]);

  
  // Message handling
  const showMessage = useCallback((msg, duration = 3000) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), duration);
  }, []);

  // Task checking functions
  const completeTask = useCallback(
    async (task) => {
      const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      const userRef = db.collection('users').doc(userId);
      
      const userDoc = await userRef.get();
      const userData = userDoc.data();
      const completedTasks = userData.completedTasks || [];

      // 計算已完成任務的總 taskPoints
      const completedTaskPoints = completedTasks.reduce((total, taskId) => {
        const task = tasks.find(t => t.id === taskId);
        return task ? total + task.taskPoints : total;
      }, 0);

      // 加上剛完成的任務的 taskPoints
      const newTotalTaskPoints = completedTaskPoints + task.taskPoints;

      const updates = {
        completedTasks: firebase.firestore.FieldValue.arrayUnion(task.id),
        taskPoints: newTotalTaskPoints,
        points: firebase.firestore.FieldValue.increment(task.taskPoints),
      };

      if (task.characterReward) {
        updates.availableCharacters = firebase.firestore.FieldValue.arrayUnion(task.characterReward);
      }

      if (task.skinReward) {
        if (Array.isArray(task.skinReward)) {
          updates.availableSkins = firebase.firestore.FieldValue.arrayUnion(...task.skinReward);
        } else {
          updates.availableSkins = firebase.firestore.FieldValue.arrayUnion(task.skinReward);
        }
      }

      try {
        await userRef.update(updates);
        setCompletedTasks((prev) => [...prev, task.id]);
        setRewards((prev) => [...prev, task.id]);

        let message = `You have earned ${task.taskPoints} CATZ!`;
        if (task.characterReward) {
          message = `You have a new character!`;
        }
        if (task.skinReward) {
          message = `You have unlocked ${task.skinReward.length} skins!`;
        }
        showMessage(message);

        fetchUserData();
      } catch (error) {
        console.error('Error completing task:', error);
      }
    },
    [user, showMessage, fetchUserData]
  );

  // Memoized sorted tasks
  const sortedTasks = useMemo(() => {
    return [...tasks].sort((a, b) => {
      const aCompleted = completedTasks.includes(a.id);
      const bCompleted = completedTasks.includes(b.id);
      return aCompleted - bCompleted;
    });
  }, [tasks, completedTasks]);


  // Meme handling
  const showMeme = useCallback(() => setMeme(true), []);
  const closeMeme = useCallback(() => {
    const twitterTask = tasks.find((task) => task.id === 'twitter');
    if (twitterTask) {
      completeTask(twitterTask);
    }
    setMeme(false);
  }, [tasks, completeTask]);

  // **Moved all helper functions before `handleTaskCompletion`**

  /*
  const createCommentPayload = (comment) => {
    try {
      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(comment)
        .endCell();
      return body.toBoc().toString('base64');
    } catch (error) {
      console.error('Error creating payload:', error);
      return null;
    }
  };
  */

  const handleClaim = useCallback(
    (task) => {
      completeTask(task);
      setClaimableTasks((prev) => prev.filter((id) => id !== task.id));
    },
    [completeTask]
  );

  const checkTransactionStatus = useCallback(
    async (task) => {
      try {
        const response = await fetch(
          `https://toncenter.com/api/v2/getTransactions?address=${userWalletAddress}&limit=10&to_lt=0&archival=true`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const transactions = data.result || [];

        const filteredTransactions = transactions.filter((tx) => {
          return (
            tx.out_msgs[0] &&
            (tx.out_msgs[0].message === 'CATZ' || tx.in_msg.message === 'CATZ') &&
            parseInt(tx.out_msgs[0].value) >= 0.1 * 1e9 * 0.9
          );
        });

        if (filteredTransactions.length > 0) {
          await handleClaim(task);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    },
    [userWalletAddress, handleClaim]
  );


  const checkTelegramSubscription = useCallback(
    async (task) => {
      const currentUserID = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
      window.open(task.url, '_blank');

      try {
        const response = await fetch(
          `https://api.telegram.org/bot${BOT_TOKEN}/getChatMember?chat_id=${task.channelId}&user_id=${currentUserID}`
        );
        const data = await response.json();

        if (data.ok && ['member', 'administrator', 'creator'].includes(data.result.status)) {
          completeTask(task);
        } else {
          showMessage('Please subscribe to the specified Telegram channel to complete this task.');
        }
      } catch (error) {
        console.error('Error checking Telegram subscription:', error);
        showMessage('An error occurred while checking the subscription.');
      }
    },
    [showMessage, completeTask]
  );

  const checkTelegramPremium = useCallback(
    (task) => {
      const currentUserPremium = window.Telegram.WebApp.initDataUnsafe.user.is_premium;
      if (currentUserPremium) {
        completeTask(task);
      } else {
        showMessage('You need to be a Telegram Premium user to complete this task.');
      }
    },
    [showMessage, completeTask]
  );

  const checkTonWalletConnection = useCallback(
    (task) => {
      if (wallet) {
        completeTask(task);
      } else {
        showMessage('Please connect your Ton Wallet to complete this task.');
      }
    },
    [wallet, showMessage, completeTask]
  );

  const checkNameContains = useCallback(
    (task) => {
      const { first_name, last_name } = window.Telegram.WebApp.initDataUnsafe.user;
      if (first_name.includes('🌞') || first_name.includes('🌜') || last_name.includes('🌞') || last_name.includes('🌜')) {
        completeTask(task);
      } else {
        showMessage('Your name must contain "🌞" or "🌜" to complete this task.');
      }
    },
    [showMessage, completeTask]
  );

  const checkFriendsCount = useCallback(
    (task) => {
      if (userData.friends?.length >= 5) {
        completeTask(task);
      } else {
        showMessage('You need at least 5 friends to complete this task.');
      }
    },
    [userData, showMessage, completeTask]
  );

  const checkMaxCombos = useCallback(
    (task) => {
      if (maxCombos >= task.requiredCombos) {
        completeTask(task);
      } else {
        showMessage(`You need ${task.requiredCombos} max combos to complete this task.`);
      }
    },
    [maxCombos, showMessage, completeTask]
  );

  const checkTodayGamePoints = useCallback(
    (task) => {
      if (todayGamePoints >= task.requiredPoints) {
        completeTask(task);
      } else {
        showMessage(`You need ${task.requiredPoints}/500 Progress to complete this task.`);
      }
    },
    [todayGamePoints, showMessage, completeTask]
  );

  const checkGamePoints = useCallback(
    (task) => {
      if (userData.points >= task.requiredPoints) {
        completeTask(task);
      } else {
        showMessage(`You need ${task.requiredPoints} CATZ to complete this task.`);
      }
    },
    [userData, showMessage, completeTask]
  );

  const checkStory = useCallback(
    (task) => {
      if (userData.sharedStory) {
        completeTask(task);
      } else {
        navigate('/Friend', { state: { story: 'Share' } });
      }
    },
    [userData, completeTask, navigate]
  );

  /*
  const initiateTransfer = useCallback(
    async (task) => {
      try {
        if (!wallet) {
          showMessage('Please connect your TON wallet first.');
          return;
        }

        tonConnectUI.setConnectRequestParameters({ state: 'loading' });

        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 60 * 20,
          messages: [
            {
              address: ourWalletAddress,
              amount: (0.1 * 1e9).toString(),
              payload: createCommentPayload('CATZ'),
            },
          ],
        };

        await tonConnectUI.sendTransaction(transaction);

        const userId = window.Telegram.WebApp.initDataUnsafe.user.id.toString();
        const userRef = db.collection('users').doc(userId);
        await userRef.update({ pendingTransfer: firebase.firestore.FieldValue.arrayUnion('catz') });
        setTaskStarted(true);
        await checkTransactionStatus(task);
      } catch (error) {
        console.error('Error initiating transfer:', error);
      }
    },
    [wallet, tonConnectUI, checkTransactionStatus, showMessage]
  );
*/
  const checkBuySkin = useCallback(
    (task) => {
      const availableSkins = userData.availableSkins || [];
      const hasNewSkin = availableSkins.some(skin => ![101, 201, 301].includes(skin));
      
      if (hasNewSkin) {
        completeTask(task);
      } else {
        showMessage('You need to buy a new skin to complete this task.');
      }
    },
    [userData, showMessage, completeTask]
  );

  // Now we can declare `handleTaskCompletion` since all dependencies are defined
  const handleTaskCompletion = useCallback(
    (task) => {
      switch (task.type) {
        case 'click':
          window.open(task.url, '_blank');
          setClaimableTasks((prev) => [...prev, task.id]);
          break;
        case 'telegram':
          checkTelegramSubscription(task);
          break;
        case 'premium':
          checkTelegramPremium(task);
          break;
        case 'ton_wallet':
          checkTonWalletConnection(task);
          break;
        /*case 'ton_transfer':
          initiateTransfer(task);
          break;*/
        case 'name_contains':
          checkNameContains(task);
          break;
        case 'friends':
          checkFriendsCount(task);
          break;
        case 'max_combos':
          checkMaxCombos(task);
          break;
        case 'todayGamePoints':
          checkTodayGamePoints(task);
          break;
        case 'gamePoints':
          checkGamePoints(task);
          break;
        case 'story':
          checkStory(task);
          break;
        case 'twitter':
          showMeme();
          break;
          /*
        case 'telegram_mode':
          checkTelegram_mode(task);
          break;*/
        case 'buy_skin':
          checkBuySkin(task);
          break;
        default:
          showMessage('Unknown task type.');
      }
    },
    [
      checkTelegramSubscription,
      checkTelegramPremium,
      checkTonWalletConnection,
      //initiateTransfer,
      checkNameContains,
      checkFriendsCount,
      checkMaxCombos,
      checkTodayGamePoints,
      checkGamePoints,
      //checkTelegram_mode,
      checkStory,
      showMeme,
      showMessage,
      checkBuySkin,
    ]
  );

  // Countdown timer
  useEffect(() => {
    const endTime = Date.UTC(2024, 11, 31, 23, 59, 0, 0);

    const calculateTimeLeft = () => {
      const now = Date.now();
      const timeDiff = endTime - now;
      if (timeDiff <= 0) {
        return { days: '0', hours: '00', minutes: '00', seconds: '00' };
      }
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = String(Math.floor((timeDiff / (1000 * 60 * 60)) % 24)).padStart(2, '0');
      const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');
      return { days, hours, minutes, seconds };
    };

    setTimeLeft(calculateTimeLeft());

    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Render
  return (
    <>
      <div className="countdown-container">
        <div className="countdown">
          <span className="time-label">TASK END : </span>
          <span className="time">{timeLeft.days}</span>
          <span className="time-label">DAYS</span>
          <span className="time">{timeLeft.hours}</span>
          <span className="time-label">HOURS</span>
          <span className="time">{timeLeft.minutes}</span>
          <span className="time-label">MINS</span>
          <span className="time">{timeLeft.seconds}</span>
          <span className="time-label">SEC</span>
        </div>
      </div>
      {message && <div className="t-message">{message}</div>}
      {meme && (
        <div className="meme">
          <img src="/asset/WeDontDoThatHere.png" alt="meme" />
          <button className="close-meme-button" onClick={closeMeme}>
            Got it
          </button>
        </div>
      )}
      <div className="task-container">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h2>TASKS</h2>
            <ul className="task-list">
              {sortedTasks
                .filter((task) => !completedTasks.includes(task.id))
                .map((task) => (
                  <li
                    key={task.id}
                    className="task-item" 
                  style={task.airdrop ? {
                     backgroundImage: `url(${process.env.PUBLIC_URL}/asset/Airdrop.png)`,
                     backgroundSize: 'contain',
                     backgroundPosition: 'center',
                     backgroundRepeat: 'no-repeat',
                  } : {}}
                  >
                    <span className="task-icon">{task.icon}</span>
                    <div className="task-details">
                      <div className="task-name">{task.name}</div>
                      {task.rewardsType === 'point' && (
                        <div className="task-points">
                          +{task.taskPoints} CATZ
                          <img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '4vw', height: 'auto' }}/>
                        </div>
                      )}
                      {task.rewardsType === 'skin' && <div className="task-points">{task.rewardsText}</div>}
                      {task.rewardsType === 'character' && (
                        <div className="task-points">{task.rewardsText}</div>
                      )}
                    </div>
                    <button
                      className={`task-button ${
                        claimableTasks.includes(task.id) ? 'claim' : 'start'
                      }`}
                      onClick={() =>
                        claimableTasks.includes(task.id)
                          ? handleClaim(task)
                          : handleTaskCompletion(task)
                      }
                    >
                      {claimableTasks.includes(task.id)|| (['story'].includes(task.type) && userData.sharedStory)
                        ? 'CLAIM'
                        : ['login', 'max_combos', 'premium', 'friends', 'telegram_mode', 'ton_wallet', 'todayGamePoints','gamePoints','buy_skin'].includes(task.type) 
                        ? 'CHECK'
                        : 'START'}
                    </button>
                  </li>
                ))}
            </ul>
            <br />
            <h2>REWARDS</h2>
          
          {/* 登入獎勵 */}
          <div className="reward-item task-item">
            <span className="task-icon">🎉</span>
            <div className="task-details">
              <div className="task-name">Total login rewards</div>
              <div className="task-points">
                +{loginRewards} CATZ
                <img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '4vw', height: 'auto' }}/>
              </div>
            </div>
          </div>
          {/* 朋友獎勵 */}
          <div className="reward-item task-item">
            <span className="task-icon">🤝</span>
            <div className="task-details">
              <div className="task-name">Total Friend rewards</div>
              <div className="task-points">
                {friendRewards === 0 ?'You have no friends yet!':'+' + friendRewards + " CATZ"}
                {friendRewards > 0 && (<img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '4vw', height: 'auto' }}/>)}
              </div>
            </div>
          </div>
          {/* 遊戲獎勵 */}
          <div className="reward-item task-item">
            <span className="task-icon">🤝</span>
            <div className="task-details">
              <div className="task-name">Total Game rewards</div>
              <div className="task-points">
                {gameRewards === 0 ? 'Go shoot your cat!':'+' + gameRewards + " CATZ"}
                {gameRewards > 0 && (<img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '4vw', height: 'auto' }}/>)}
              </div>
            </div>
          </div>
          {/* 任務獎勵 */}
          <ul className="task-list">
            {rewards.map(rewardId => {
              const task = tasks.find(t => t.id === rewardId);
              return task ? (
                <li 
                  key={task.id} 
                  className={`reward-item task-item `}
                  style={task.airdrop ? {
                    backgroundImage: `url(${process.env.PUBLIC_URL}/asset/Airdrop.png)`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                 } : {}}
                >
                  <span className="task-icon">{task.icon}</span>
                  <div className="task-details">
                  <div className="task-name">{task.name}</div>
                    {task.rewardsType === 'point' ? (
                    <div className="task-points">
                    +{task.taskPoints} CATZ
                    <img alt="coin" src={`${process.env.PUBLIC_URL}/asset/Catz_s.webp`} style={{ width: '4vw', height: 'auto' }}/>
                  </div>
                    ) : task.rewardsType === 'skin' ? (
                    <div className="task-points">{task.rewardsText}</div>
                    ) : task.rewardsType === 'character' ? (
                    <div className="task-points">{task.rewardsText}</div>
                    ) : null}
                </div>
                  <button className="task-button completed">COMPLETED</button>
                </li>
              ) : null;
            })}
          </ul>
          </>
        )}
      </div>
    </>
  );
}

export default React.memo(Task);
